<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Brands</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <div>
              <v-row v-if="!loading">
                <v-col
                  md="3"
                  v-for="(data, index) in brands"
                  :key="index + 'brand'"
                >
                  <div class="companyLogo"  @click="filterPage(`?brand=${data.brandName}`)">
                    <img :src="data.image" alt="" />
                    <div class="titleBox">{{ data.brandName }}</div>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-row v-if="!loading">
                <v-col md="12" class="text-center viewMore mt-5">
                  <v-btn @click="next(allBrands.pagination.next)" large
                    >Load More <v-icon> mdi-chevron-right </v-icon
                    ><v-icon> mdi-chevron-right </v-icon></v-btn
                  >
                </v-col>
              </v-row>
              -->
              <v-row v-if="loading" class="justify-center">
                <Loading/>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Brand",
  data: () => ({
    brands: [],
    limit: 12,
    page: 1,
    loading:true
  }),
  components:{
      Loading
  },
  computed: {
    ...mapGetters(["allBrands"]),
  },
  methods: {
    ...mapActions(["getBrandFrontend"]),
     filterPage(data) {
      this.$router.push(`/search/${data}`);
    },
    async next(data) {
      this.loading = true;
      await this.getBrandFrontend({ limit: this.limit, page: data });
      this.brands = this.allBrands.results;
      this.pagination = this.allBrands.pagination;
      this.loading = false;
    },
  },
  async created() {
    await this.getBrandFrontend({ limit: this.limit, page: this.page });
    this.brands = this.allBrands.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}

.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.companyLogo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
  }
}
</style>